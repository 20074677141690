import { Injectable } from "@angular/core";
import { ApiService } from "../../../common/services/api/api.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { User, Role } from "../../../../app/auth/models";
import { of } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AuthService extends ApiService {
	//public
	public currentUser: Observable<User>;

	//public
	public currentUserSubject: BehaviorSubject<User>;

	constructor(private httpClient: HttpClient) {
		super(httpClient);
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	getHealthCheck(payload: any) {
		return this.get("ux/system_check/", payload);
	}

	doLogin(payload: any, header: any) {
		return this.postWithHeader("ux/api-token-enc-per-ui/", payload, header);
	}

	doRegister(payload: any) {
		return this.post("ux/org/register/", payload);
	}

	doCRMRegister(payload: any) {
		return this.post("ux/org/crmregister/", payload);
	}

	logout() {
		return this.post("ux/common/user/profile/logout/");
	}

	checkEmailAvailability(payload: any) {
		return this.post("ux/org/verifyorg", payload);
	}
	getPreferences(payload: any) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}

	getCaptcha(payload: any) {
		return this.get("ux/org/captcha/", payload);
	}

	forgotPassword(payload: any) {
		return this.post("ux/org/forgotPassword/", payload);
	}

	resetUIPassword(payload: any) {
		return this.post("ux/common/user/invite/reset_password/", payload);
	}

	resetPassword(payload: any) {
		return this.post("ux/common/user/profile/reset_password/", payload);
	}

	getTokenvalidate(payLoad: any) {
		return this.post("ux/validate-ttl/", payLoad);
	}

	validate2FaOTP(payLoad: any) {
		return this.post("ux/validate-otp/", payLoad);
	}
	postMobileLogin(payLoad: any) {
		return this.post("ux/api-mobile_auth/", payLoad);
	}
	validateOTP(payLoad: any) {
		return this.post("ux/valid-sms-otp/", payLoad);
	}

	getDCs() {
		return this.get("ux/org/regions/");
	}

	getLoginSettingsConfig(payload: any) {
		return this.post("ux/org/login_setting_data/", payload);
	}

	addDemoData(payload: any) {
		return this.post("ux/common/demodata/demodata/demo/",payload);
	}

	doMicrosoftLogin(payload: any) {
		return this.post("ux/api-sso-micro/", payload);
	}
}
