import {Injectable} from '@angular/core';
// import * as mixpanel from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core'
import {environment} from "../../../environments/environment.instance";
import jwt_decode from 'jwt-decode';
import store from "store";
import {OrgDataService} from "app/services/org-data-service.service";
import { encryptData, decryptData } from "app/common/utils/utils";

@Injectable({
	providedIn: 'root'
})
export class InfraonMixpanelService {
	userData: any;

	constructor(private _orgDataService: OrgDataService) {
		this._orgDataService.getData().subscribe(res => {
			this.userData = res
			if (res && (Object.keys(res).length > 0)){
				let enc_data = encryptData(JSON.stringify(this.userData));
				store.set("ogdata", enc_data);
			}
			else{
				let enc_st_data = store.get("ogdata");
				if (enc_st_data){
					let decrypt_Data = decryptData(enc_st_data);
					this.userData = JSON.parse(decrypt_Data);
				}
			}
			
		});
	}

	/**
	 * Initialize mixpanel.
	 *
	 * @param {string} UUID
	 * @memberof InfraonMixpanelService
	 */
	init(): void {
		mixpanel.init(environment.mixpanel);
		let user_data = this.getDecodeValue();
		mixpanel.add_group("organization", user_data.org_id)
		mixpanel.identify(user_data.user_id);
	}

	/**
	 * Getting the data from JWT token.
	 *
	 * @memberof InfraonMixpanelService
	 */
	getDecodeValue(): any {
		try {
			const userToken = store.get("accessToken");
			let token_payload: any = jwt_decode(userToken);
			let username = token_payload?.["username"] || "";
			var splitted = username.split("@@@", 2);
			return {"username": splitted[1], "org_id": splitted[0], "user_id": token_payload?.["profile_id"] || "-1"};
		} catch (Error) {
			return {"username": "", "org_id": "", "user_id": "-1"};
		}
	}

	/**
	 * Push new action to mixpanel.
	 *
	 * @param {string} id Name of the action to track.
	 * @param {*} [action={}] Actions object with custom properties.
	 * @memberof InfraonMixpanelService
	 */
	trackEvent(id: string, action: any = {}): void {
		action["Company Name"] = this.userData?.["company_name"];
		action["User Name"] = this.userData?.["profile"]?.["full_name"];
		try{
			mixpanel.track(id, action);
		}catch (e){
			this.init();
			mixpanel.track(id, action);
		}
	}
	/**
	 * For lout clear the user session
	 * 
	 */
	logout(): void{
		mixpanel.reset()
	}
}
