import {
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { DOCUMENT } from "@angular/common";
import find from "lodash-es/find";
import { Subject, Subscription, throwError } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { User } from "app/auth/models";

import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "app/base/auth/services/auth.service";
import { UserService } from "app/base/users/services/user.service";
import { ToastrService } from "ngx-toastr";
import { AdminPopoverComponent } from "app/base/system-menu/components/admin-popover/admin-popover.component";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "app/services/common.service";
import { GlobalSearchComponent } from "./global-search/global-search.component";

import help from "app/common/dns/components/dns-help/help.json";
import { clearStore } from "app/common/utils/utils";
import { WorkflowService } from "app/base/workflow/services/workflow.service";
import { MessengerService } from "app/base/messenger-audit/services/messenger.service";
import { CommonUtilService } from "@core/services/common-util.service";
import { GlobalConstants } from "app/app.globalConstants";
import { PermissionService } from "app/common/permission/service";
import { environment } from "environments/environment";
import store from "store";
import { GetStartedService } from "app/base/get-started/services/get-started.service";
import { BrandStylingService } from "app/base/brand-styling/brand-styling.service";
import { WSSService } from "app/common/services/wss/wss.service";
import { SelfServicePortalService } from "app/self-service-portal/services/self-service-portal.service";
import { SspConfigurationService } from "app/base/ssp-configuration/services/ssp-configuration.service";
@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
	// @Inject(DOCUMENT) private document: Document
	@ViewChild("demoVideo") demoVideo!: ElementRef;
	layoutConfig = {
		layoutStyle: "",
		borderStyle: "",
		themeColor: "",
		// layoutStyle:JSON.parse(localStorage.getItem("config")).layout?.layoutStyle ? JSON.parse(localStorage.getItem("config")).layout.layoutStyle : 'fixed', // 'fixed', 'spaced-out','fluent'
		// borderStyle: JSON.parse(localStorage.getItem("config")).layout?.borderStyle ? JSON.parse(localStorage.getItem("config")).layout.borderStyle : 'shadow', // 'shadow','border'
		// themeColor: JSON.parse(localStorage.getItem("config")).layout?.themeColor ? JSON.parse(localStorage.getItem("config")).layout.themeColor: 'light' // 'light','dark','semi-dark'
	};
	@Input() rows: Array<any>;
	public horizontalMenu: boolean;
	public hiddenMenu: boolean;
	currentscreen: any;
	tempScreen: any;
	public isNewFeatureSidebar = true;
	public isBookmarked: boolean;
	public isSelfServicePortal = false;
	public coreConfig: any;
	public buttonsRight: any;
	public currentSkin: string;
	public themeColor: string;
	public prevSkin: string;
	showVideo: boolean = false;
	gettingStartedStep: boolean = true;
	public currentUser: User;
	public totalGettingStep: any = [];
	public completedStep: any = [];
	public rem_days: any;
	public languageOptions: any;
	public navigation: any;
	public selectedLanguage: any;
	public showNotification = false;
	public notificationCount: any = 0;
	public currentFeatureVideo: any;
	public isDisplay: boolean;
	featureVideoMap = new Map([
		["Infraon-Assets", "https://infraon-social.s3.ap-south-1.amazonaws.com/videos/Infraon_Assets_Demo.mp4"],
		["Infraon-ITSM", "https://infraon-social.s3.ap-south-1.amazonaws.com/videos/Infraon_ITSM_Demo_Video.mp4"],
		[
			"Infraon-Helpdesk",
			"https://infraon-social.s3.ap-south-1.amazonaws.com/videos/Infraon_Helpdesk_Demo_Video.mp4",
		],
	]);

	// public goalChartoptions: Partial<any>;
	private $goalStrokeColor2 = "#7367F0";
	private $strokeColor = "#ebe9f1";
	private $textHeadingColor = "#5e5873";
	data: any;
	currentMsgTab = "outgoing";
	public showEditIcon = false;
	public showTicketSidebar = false;
	public ssResNavOpen = false; // self service responsive nav
	closeResult = "";
	isAnimated: boolean = false;
	@HostBinding("class.floating-nav")
	public isFixed = false;

	@HostBinding("class.navbar-static-style-on-scroll")
	public windowScrolled = false;

	// Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
	moduleData: any;
	layoutStyle: any;
	icon: any;
	navbarColor: any;
	logo: any;
	incident_permission: any;
	showDarkLightSwitch = false;
	request_permission: any = {};
	asset_id: any = "";
	showRemainDays: boolean = false;
	showGettingStarted: boolean = false;
	onPremise: boolean;
	public isAdminUser = false;
	sspNavMenu: any[] = [];
	activeModuleKey: string;
	activeDataItem: any;
	currentRoute: any;
	@HostListener("window:scroll", [])
	onWindowScroll() {
		if (
			(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
			this.coreConfig.layout.navbar.type == "floating-nav"
		) {
			this.windowScrolled = true;
		} else if (
			(this.windowScrolled && window.pageYOffset) ||
			document.documentElement.scrollTop ||
			document.body.scrollTop < 10
		) {
			this.windowScrolled = false;
		}
	}

	// Private
	private _unsubscribeAll: Subject<any>;
	private selfServiceSubscription: Subscription;
	/**
	 * Constructor
	 *
	 * @param {Router} _router
	 * @param {AuthService} _authService
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreMediaService} _coreMediaService
	 * @param {MediaObserver} _mediaObserver
	 * @param {TranslateService} _translateService
	 */
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private _router: Router,
		private _authService: AuthService,
		private _coreConfigService: CoreConfigService,
		private _coreMediaService: CoreMediaService,
		private _coreSidebarService: CoreSidebarService,
		private _mediaObserver: MediaObserver,
		public _translateService: TranslateService,
		private _userService: UserService,
		private _toastr: ToastrService,
		private modalService: NgbModal,
		private _commonService: CommonService,
		private _service: WorkflowService,
		public msgService: MessengerService,
		public _commonUtilService: CommonUtilService,
		private permissionService: PermissionService,
		private _getStartedService: GetStartedService,
		private _brandStylingService: BrandStylingService,
		private _wssService: WSSService,
		private _sspService: SelfServicePortalService,
		private _sspConfigService: SspConfigurationService
	) {
		//TODO
		this.selfServiceSubscription = this._coreConfigService._selfServiceSubject.subscribe((selfService) => {
			if (selfService === "self-service") {
				this.isSelfServicePortal = true;
			} else {
				this.isSelfServicePortal = false;
			}
		});
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));

		this.languageOptions = {
			en: {
				title: "English",
				flag: "us",
			},
			hi: {
				title: "Hindi",
				flag: "in",
			},
		};
		// (this.data = {
		// 	goalOverview: {
		// 		series: "[2.3]",
		// 		analyticsData: {
		// 			completed: "786,617",
		// 			inProgress: "13,561",
		// 		},
		// 	},
		// }),
		// (this.goalChartoptions = {
		// 	chart: {
		// 		height: 40,
		// 		width: 50,
		// 		type: "radialBar",
		// 		sparkline: {
		// 			enabled: true,
		// 		},
		// 		dropShadow: {
		// 			enabled: true,
		// 			blur: 3,
		// 			left: 1,
		// 			top: 100,
		// 			opacity: 0.1,
		// 		},
		// 	},
		// 	colors: [this.$goalStrokeColor2],
		// 	plotOptions: {
		// 		radialBar: {
		// 			offsetY: 0,
		// 			offsetX: 10,
		// 			startAngle: -179,
		// 			endAngle: 180,
		// 			hollow: {
		// 				size: "25%",
		// 			},
		// 			track: {
		// 				background: this.$strokeColor,
		// 				strokeWidth: "80%",
		// 			},
		// 			dataLabels: {
		// 				name: {
		// 					show: true,
		// 					offsetY: 5,
		// 					color: this.$textHeadingColor,
		// 					fontSize: "0.8rem",
		// 					fontWeight: "200",
		// 				},
		// 				offsetX: 30,
		// 				value: {
		// 					show: false,
		// 					offsetY: 5,
		// 					color: this.$textHeadingColor,
		// 					fontSize: "0.8rem",
		// 					fontWeight: "200",
		// 				},
		// 			},
		// 		},
		// 	},
		// 	fill: {
		// 		gradient: {
		// 			// shade: 'dark',
		// 			// type: 'horizontal',
		// 			// shadeIntensity: 0.5,
		// 			gradientToColors: ["#7367F0"],
		// 			inverseColors: true,
		// 			opacityFrom: 1,
		// 			opacityTo: 1,
		// 			stops: [0, 100],
		// 		},
		// 	},
		// 	grid: {
		// 		margin: {
		// 			left: 0,
		// 		},
		// 	},
		// });

		// Set the private defaults
		this._unsubscribeAll = new Subject();
		// this.tempScreen = JSON.parse(localStorage.getItem("config")).layout.skin;
		// this._commonService.updateCurrentSceen(this.tempScreen);

		// this._commonService.currentscreen.subscribe((val) => {
		// 	this.currentscreen = val;
		// 	// window.location.reload();
		// 	this.ngOnInit();
		// });
		// if (this.currentscreen !== this.tempScreen) {
		// 	this.currentscreen = this.tempScreen;
		// 	window.location.reload();
		// }
		let currentSkin = JSON.parse(localStorage.getItem("config")).layout.skin;
		localStorage.setItem("prevSkin", currentSkin);
		this.icon = localStorage.getItem("imgicon");

		// this._commonService.prevSkin.subscribe((val) => {
		// 	localStorage.setItem("prevSkin", val);
		// });
		if (window.location.href.indexOf("/error") > -1) {
			// Configure the layout
			this._coreConfigService.setConfig(
				{
					layout: {
						navbar: {
							hidden: true,
						},
						menu: {
							hidden: true,
						},
					},
				},
				{ emitEvent: true }
			);
		} else {
			// let navbarColor = this.navbarColorValue;
			// let logoColor = this.logoContainerColor;
			// let themeColor = this.layoutConfig.themeColor;
			// let layoutStyle = this.layoutConfig.layoutStyle;
			// let icon = localStorage.getItem("imgicon");
			// console.log("Hiiiiiiiiii")
			// this._coreConfigService.setConfig(
			// 	{
			// 		// layout: {
			// 		// 	themeColor: themeColor,
			// 		// 	layoutStyle: layoutStyle,

			// 		// 	menu: {
			// 		// 		hidden: false,
			// 		// 		logoBackgroundColor: logoColor,
			// 		// 		icon: icon,
			// 		// 	},
			// 		// 	navbar: {
			// 		// 		hidden: false,
			// 		// 		backgroundColor: navbarColor,
			// 		// 	},
			// 		// },
			// 	},
			// 	{ emitEvent: true }
			// );
			this._coreConfigService.getConfig().subscribe((value) => {
				if (value?.layout?.navbar?.moduleName) {
					this.moduleData = help[value?.layout?.navbar?.moduleName];
				} else {
					this.moduleData = null;
				}
			});
		}
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this.document.body.classList.add("getting-started-modal");
		this.showGettingStarted = true;
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}

	toggleSidebarEvent(key) {
		this._commonUtilService.setAddWorkFlowSidebar({ key: key, flag: true });
	}

	/**
	 * Set the language
	 *
	 * @param language
	 */
	setLanguage(language): void {
		// Set the selected language for the navbar on change
		this.selectedLanguage = language;

		// Use the selected language id for translations
		this._translateService.use(language);
	}

	/**
	 * Toggle Dark Skin
	 */
	toggleDarkSkin() {
		// Get the current skin
		this._coreConfigService
			.getConfig()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.currentSkin = config.layout.skin;
				this.themeColor = config.layout.themeColor;
			});
		if (this.themeColor === "dark") {
			this._coreConfigService.setConfig({ layout: { themeColor: "default" } });
			this.document.body.classList.remove("dark-layout");
		} else {
			this._coreConfigService.setConfig({
				layout: {
					themeColor: "dark",
					skin: "dark",
					navbar: {
						backgroundColor: "bg-transparent",
					},
					menu: {
						logoCustomBackgroundColor: true,
						logoBackgroundColor: "bg-transparent",
					},
				},
			});
			this.document.body.classList.add("dark-layout");
			// this._commonService.navBgColor.subscribe((val) => {
			// 	if(val === 'bg-card'){

			// 		this._coreConfigService.setConfig({
			// 			layout: {
			// 				navbar: {
			// 					backgroundColor: 'bg-card',
			// 				}
			// 			},
			// 		})
			// 	}
			// })
			this._commonService.logoBgColor.subscribe((val) => {
				if (val === "bg-transparent") {
					this._coreConfigService.config.setConfig({
						layout: {
							menu: {
								logoBackgroundColor: "bg-transparent",
							},
						},
					});
				}
			});
		}
		if (this.currentSkin === "fluent") {
			let darkMode;
			this._commonService.enableDarkMode.subscribe((val) => {
				darkMode = val;
			});

			this._commonService.updateEnableDarkMode(true);
			// localStorage.setItem("fluentDarkMode", darkMode);
			localStorage.setItem("prevSkin", this.currentSkin);
			this._coreConfigService.setConfig({ layout: { skin: "fluent" } });
			this.document.body.classList.forEach((e) => {
				if (e === "dark-layout") {
					this._coreConfigService.setConfig({
						layout: { is_fluent_dark: false },
					});
					this.document.body.classList.remove("dark-layout");
				} else {
					this._coreConfigService.setConfig({
						layout: { is_fluent_dark: true },
					});
					this.document.body.classList.add("dark-layout", "mat-typography");
				}
			});
		} else {
			this.prevSkin = localStorage.getItem("prevSkin");
			if (this.currentSkin === "dark") {
				this._coreConfigService.setConfig(
					{ layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
					{ emitEvent: true }
				);
			} else {
				localStorage.setItem("prevSkin", this.currentSkin);
				this._coreConfigService.setConfig({ layout: { skin: "dark" } });
			}
		}
		localStorage.setItem("themeColor", this.themeColor);
	}

	/**
	 * Logout method
	 */
	logout() {
		let accessType = store.get("accessType");
		this._authService.logout().subscribe(
			(res) => {
				clearStore([
					"accessToken",
					"image_per",
					"accessType",
					"is_expired",
					"remaining_days",
					"is_admin",
					"show_banner",
					"product",
				]);

				if (accessType == 2) {
					this._router.navigate(["/auth/login"], { queryParams: { auth_type: "2" } });
				} else {
					this._router.navigate(["/auth/login"]);
				}
			},
			(err) => {
				// store.remove("accessToken");
				if (accessType == 2) {
					this._router.navigate(["/auth/login"], { queryParams: { auth_type: "2" } });
				} else {
					this._router.navigate(["/auth/login"]);
				}
			}
		);
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		if (this.isSelfServicePortal) {
			this._sspService.sspConfig.subscribe((config) => {
				if (config.length > 0) {
					this.sspNavMenu = config;
					const homePage = this.sspNavMenu.find((page) => page?.is_homepage);
					if (["dashboard", "report"].includes(homePage.module_key)) {
						this.activeDataItem = homePage.data[0];
						this.activeModuleKey = homePage.module_key;
					}
				} else {
					this.getSSPConfig();
				}
			});
		}
		// Subscribe to the config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			// if(this._router && this._router.url && this._router.url.indexOf('workflow/edit') > -1) {
			// 	config.layout.navbar['isAllowEdit'] = true
			// } else {
			// 	config.layout.navbar['isAllowEdit'] = false
			// }
			this.coreConfig = config;
			if (this.coreConfig.layout?.menu?.logo != "") {
				this.logo = this.coreConfig?.layout?.menu?.logo;
			} else {
				if (localStorage.getItem("imglogo") != "null" && localStorage.getItem("imglogo") != "undefined") {
					this.logo = localStorage.getItem("imglogo");
				} else {
					this.logo = "assets/images/infraon_logo.svg";
				}
			}
			this.horizontalMenu = config.layout.type === "horizontal";
			this.hiddenMenu = config.layout.menu.hidden === true;
			this.currentSkin = config.layout.skin;
			this.isBookmarked = this.coreConfig.layout.navbar.bookmark;
			this.buttonsRight = this.coreConfig.layout.navbar.buttonsRight;
			// this.layoutConfig.themeColor =this.coreConfig.layout.themeColor;
			this.themeColor = this.coreConfig.layout.themeColor;
			this.layoutConfig.layoutStyle = this.coreConfig.layout.layoutStyle;
			this.layoutStyle = this.coreConfig.layout.layoutStyle;
			// this.navbarColor = this.coreConfig.layout.navbar.backgroundColor
			this.icon = this.coreConfig.layout.menu.icon;
			// Fix: for vertical layout if default navbar fixed-top than set isFixed = true
			if (this.coreConfig.layout.type === "vertical") {
				setTimeout(() => {
					this.coreConfig = config;
					if (this.coreConfig.layout.navbar.type === "fixed-top") {
						this.isFixed = true;
					}
				}, 0);
			}
			this.isDisplay = false;
		});
		this.getNotificationCount();
		this.onPremise = environment.on_premise;
		this.isAdminUser = store.get("is_admin");
		if (!this.onPremise) {
			let currentProduct = store.get("product");
			this.currentFeatureVideo = this.featureVideoMap.get(currentProduct);
			if (this.currentFeatureVideo) {
				this.showVideo = true;
			}
			if (
				this.gettingStartedStep &&
				!this.isSelfServicePortal &&
				!this.coreConfig.layout.adminPortal &&
				!this.coreConfig.layout.supportPortal &&
				this.isAdminUser
			) {
				this.getGettingStartedProgress();
			}
			this.showRemainDays = store.get("show_banner");
			this.rem_days = store.get("remaining_days");
			this._getStartedService.updateStepProgress.subscribe((isChange: boolean) => {
				if (isChange) {
					this.getGettingStartedProgress();
				}
			});
		}

		// get the currentUser details from localStorage
		// this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
		if (localStorage.getItem("accessToken")) {
			this.getCurrentUserProfile();
		}

		// Horizontal Layout Only: Add class fixed-top to navbar below large screen
		if (this.coreConfig.layout.type == "horizontal") {
			// On every media(screen) change
			this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
				const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
				if (isFixedTop) {
					this.isFixed = false;
				} else {
					this.isFixed = true;
				}
			});
		}

		// Set the selected language from default languageOptions
		this.selectedLanguage = find(this.languageOptions, {
			id: this._translateService.currentLang,
		});
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.incident_permission = user_permissions["incident"];
		if (!this.incident_permission || this.incident_permission == undefined) {
			let incident_add_permission = this.permissionService.isGranted(["incident"], ["add"]);
			if (incident_add_permission == true) {
				this.incident_permission = { add: true };
			}
		}
		this.request_permission = user_permissions["incident"];
		if (!this.request_permission || this.request_permission == undefined) {
			let request_add_permission = this.permissionService.isGranted(["request_process"], ["add"]);
			if (request_add_permission == true) {
				this.request_permission = { add: true };
			}
		}
		if (environment?.test_setup) {
			this.showDarkLightSwitch = true;
		}
		this._commonService.initCallApp.next(true)
		// this.currentRoute = this._router.routerState.snapshot.url;
	}
	ngAfterViewInit() {
		this._commonService.showDemoVideo.subscribe((isShow) => {
			if (isShow && this.showVideo) {
				this.modalDemoVideo(this.demoVideo);
			}
		});
		this._wssService
			.getWsDataStore()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res: any) => {
				if (res && res?.channel === "infraon_notification:update") {
					this.getNotificationCount();
				}
			});
	}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
		if (this.selfServiceSubscription) {
			this.selfServiceSubscription.unsubscribe();
		}
	}

	/**
	 * Marketplace navigate method
	 */
	goToMarketplace() {
		this._router.navigate(["/marketplace"]);
	}
	notificationCountData(count) {
		this.notificationCount = count;
	}

	bookMark = (data: any) => {
		this.isBookmarked = !this.isBookmarked;
		let payload = {
			module: data,
			bookmark: this.isBookmarked,
			type: "page",
			page_url: this._router.url,
		};
		this._userService
			.saveUserPreference(payload)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["status"] === "success") {
							if (this.isBookmarked == true) {
								this._toastr.success(
									this._translateService.instant("Message.msg_user_preference_save"),
									this._translateService.instant("Message.msg_page_bookmarked"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.success(
									this._translateService.instant("Message.msg_user_preference_save"),
									this._translateService.instant("UI.k_bookmark_removed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						} else {
							this._toastr.error(
								this._translateService.instant("Error.err_preference_save"),
								this._translateService.instant("Error.err_page_bookmark_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
					}
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	openModalAD() {
		let cssClass = "animation-disable modal-dialog-admin";
		this.modalService.open(AdminPopoverComponent, {
			centered: false,
			windowClass: cssClass,
			//animation: false,
			size: "xs",
		});
	}

	openSearch() {
		let cssClass = "animation-disable modal-dialog-search";
		this.modalService.open(GlobalSearchComponent, {
			centered: false,
			windowClass: cssClass,
			//animation: false,
			size: "xl",
		});
	}

	/**
	 * Get Loggged in User profile details, assign to subject oservable variable current user to access anywhere
	 */
	getCurrentUserProfile() {
		let accessType = store.get("accessType");
		let payload = {};
		if (accessType == 2) {
			payload["login_auth_type"] = 2;
		}
		this._userService
			.getCurrentUserProfile(payload)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response?.requester) this._coreConfigService._currentRequster.next(response?.requester);

						this._authService.currentUserSubject.next(response);
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		this._userService
			.getCurrentUserProfile()
			.pipe(
				map((response: any) => {
					if (response) {
						this._authService.currentUserSubject.next(response);
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	toggleHelp(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}

	toggleTicketSidebar() {
		const url = this._router.url;
		this.asset_id = "";
		if (url.indexOf("assets-view") != -1) {
			let url_list = url.split("/");
			this.asset_id = url_list[url_list?.length - 1];
		}
		this.showTicketSidebar = !this.showTicketSidebar;
		this._coreSidebarService.getSidebarRegistry("submit-ticket-sidebar").toggleOpen();
	}

	messengerTabEvent(key) {
		this.msgService.setMessengerTab(key);
		this.currentMsgTab = key;
	}

	toggleSsResponsiveNav = () => {
		this.ssResNavOpen = !this.ssResNavOpen;
		this.activeModuleKey = null;
		this.activeDataItem = null;
	};

	modalDemoVideo = (demoVideo) => {
		this.modalService
			.open(demoVideo, {
				centered: true,
				size: "xl", // size: 'xs' | 'sm' | 'lg' | 'xl'
				windowClass: "demo-video-modal",
			})
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	};
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			this._commonService.showDemoVideo.next(false);
			this.isAnimated = true;
			setTimeout(() => {
				this.isAnimated = false;
			}, 5000);
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			this._commonService.showDemoVideo.next(false);
			this.isAnimated = true;
			setTimeout(() => {
				this.isAnimated = false;
			}, 5000);
			return "by clicking on a backdrop";
		} else if (reason === "Cross click") {
			this._commonService.showDemoVideo.next(false);
			this.isAnimated = true;
			setTimeout(() => {
				this.isAnimated = false;
			}, 5000);
		} else {
			return `with: ${reason}`;
		}
	}
	getGettingStartedProgress = () => {
		this._getStartedService
			.getConfigurationProgress()
			.pipe(
				map((response: any) => {
					let totalGetStep = [];
					for (let i = 0; i < response.total_steps; i++) {
						totalGetStep.push(i);
					}
					this.totalGettingStep = totalGetStep;
					this.completedStep = response.completed_step_list;
					if (this.totalGettingStep.length === this.completedStep.length) {
						this.showGettingStarted = false;
					} else {
						this.showGettingStarted = true;
					}
				})
			)
			.subscribe();
	};

	goToSubscription = (event) => {
		// this._router.navigate([`/admin/instance/${this.instance_id}`]);
		event.preventDefault();
		let accessToken = store.get("accessToken");
		let abs_domain = environment?.management_url + "redirect/admin/" + accessToken;
		window.open(abs_domain, "_blank");
		// window.open(environment?.management_url, "_blank");
	};
	showNotificationSidebar(sidebar) {
		this.showNotification = true;
		this._coreSidebarService.getSidebarRegistry(sidebar).toggleOpen();
	}
	hideNotificationSidebar(e) {
		this.showNotification = false;
		this._coreSidebarService.getSidebarRegistry("notification-panel").close();
	}

	getNotificationCount = () => {
		this._userService
			.getNotificationCount()
			.pipe(
				map((response: any) => {
					if (response) {
						this.notificationCount = response?.count || 0;
					}
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};
	selectItem(subItem: any, route: string, moduleKey: string) {
		this._router.navigate([route]);
		this.activeDataItem = subItem;
		this.activeModuleKey = moduleKey;
		const navigationMap = {
			dashboard: () =>
				this._router.navigate(["/self-service/dashboard/"], { queryParams: { id: subItem.dashboard_id } }),
			report: () => this._router.navigate(["/self-service/report/"], { queryParams: { id: subItem.report_id } }),
		};
		navigationMap[moduleKey]?.();
	}
	getSSPConfig = () => {
		this._sspConfigService
			.getSSPConfiguration()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(
				(response) => {
					if (response) {
						this._sspService.sspConfig.next(response);
					}
				},
				(error) => {
					console.error("Error fetching SSP configuration:", error);
				}
			);
	};
}
